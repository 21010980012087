/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import getLanguage from '../utils/getLanguage';
import * as api from '../utils/api';
import { reloadShipments } from '../state/shipments';
import { showNotification } from '../state/notifications';
import { NavigationHeader, STEP } from './utils';
import { DeliveryDetailsFormik } from './DeliveryDetails';
import { WoltDeliveryDetailsFormik } from './WoltDeliveryDetails';

export const DeliveryInstructions = ({
  data: { shipment },
  selection,
  updateSelection,
  goNext,
  goToStep,
  goBack,
  token,
  email,
}) => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const language = useSelector(state => getLanguage(state));
  const user = useSelector(state => state.session.user);
  const onSubmit = useCallback(
    async values => {
      updateSelection(values);
      try {
        if (selection.woltDeliverySelected) {
          goToStep(STEP.WOLT_PAYMENT);
        } else {
          let response = {};
          if (selection?.selectionDone) {
            // time was chosen, set delivery time
            response = await api.setDeliveryTime(shipment.shipmentNumber, {
              ...selection,
              ...values,
              token,
              email,
              language,
            });
          } else {
            // user updated only remarks
            response = await api.updateDeliveryRemarks(shipment.shipmentNumber, {
              ...selection,
              ...values,
              token,
              email,
              language,
            });
          }

          updateSelection({
            email: !!response.email,
            calendarEntry: response.calendarEntry,
            selectionDone: false,
          });
          dispatch(reloadShipments());
          goNext();
        }
      } catch (err) {
        dispatch(showNotification('genericApiError'));
      }
    },
    [updateSelection, goNext, dispatch, shipment.shipmentNumber, selection, token, email, language]
  );
  const formProps = {
    user,
    translate,
    onSubmit,
    shipment,
    deliveryAddress: shipment.deliveryAddress,
  };

  return (
    <>
      <NavigationHeader shipmentNumber={shipment?.shipmentNumber} token={token} goBack={goBack} />
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>{translate('delivery.agree.deliveryInfo')}</Styled.h1>
      {selection.woltDeliverySelected ? (
        <WoltDeliveryDetailsFormik {...formProps} />
      ) : (
        <DeliveryDetailsFormik {...formProps} />
      )}
    </>
  );
};
