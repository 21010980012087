/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { Form, withFormik } from 'formik';
import FormField from '../components/FormField';
import Button from '../components/Button';
import Spinner from '../components/Spinner';
import * as yup from 'yup';

const EmailForm = ({ setFieldValue, isSubmitting, translate }) => {
  return (
    <Form
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        mt: 2,
        maxWidth: 400,
      }}
    >
      <Box sx={{ width: '100%', pr: 2 }}>
        <FormField
          name="email"
          label={translate('delivery.agree.askEmail')}
          sx={{ pr: 4 }}
          trailing={
            <Button
              variant="plain"
              type="submit"
              disabled={isSubmitting}
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                zIndex: 1,
                display: 'flex',
                alignItems: 'center',
                px: 2,
                fontSize: 24,
              }}
            >
              <span>&rarr;</span>
            </Button>
          }
        />
      </Box>
      {isSubmitting && (
        <Box sx={{ position: 'absolute', top: '31px', right: '-10px' }}>
          <Spinner />
        </Box>
      )}
    </Form>
  );
};

export const EmailFormik = withFormik({
  mapPropsToValues: () => ({
    email: '',
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      email: yup
        .string()
        .required(translate('form.requiredField'))
        .email(translate('form.invalidEmail')),
    }),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'EmailForm',
})(EmailForm);
