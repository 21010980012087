/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Form, withFormik } from 'formik';
import FormField from '../components/FormField';
import { ButtonBox } from './utils';
import Button from '../components/Button';
import * as yup from 'yup';
import React from 'react';
import PersonalHandoverNote from './PersonalHandoverNote';

const DeliveryDetailsForm = ({ isSubmitting, handleSubmit, shipment }) => {
  const personalHandover = shipment?.additionalServices?.some(s => s.additionalServiceCode === 'HL');
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Form
      sx={{
        width: '100%',
        my: 4,
      }}
    >
      <div sx={{ mt: 4, mb: 2 }}>{translate('delivery.details.doorCode')}</div>
      <div sx={{ maxWidth: '120px' }}>
        <FormField name="doorCode" />
      </div>

      {!personalHandover ? (
        <>
          <div sx={{ mt: 2, mb: 2 }}>{translate('delivery.details.remarks')}</div>
          <FormField name="deliveryRemarks" />
        </>
      ) : null}

      <Box sx={{ bg: 'blueLighter', px: 3, py: 1, borderRadius: 1 }}>
        <p>{translate('delivery.agree.addressCannotBeChanged')}</p>
      </Box>

      <PersonalHandoverNote sxx={{ mt: 3, mb: 4 }} shipment={shipment} />

      <ButtonBox>
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('delivery.agree.ready')}
        </Button>
      </ButtonBox>
    </Form>
  );
};

const detailsSchema = translate =>
  yup.object().shape({
    doorCode: yup.string(),
    deliveryRemarks: yup.string(),
  });

export const DeliveryDetailsFormik = withFormik({
  mapPropsToValues: ({ doorCode = '', deliveryRemarks = '' }) => ({
    doorCode,
    deliveryRemarks,
  }),

  validationSchema: ({ translate }) => detailsSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'DeliveryDetailsForm',
})(DeliveryDetailsForm);
