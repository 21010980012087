/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import 'url-search-params-polyfill'; // for IE
import Layout from '../components/layout';
import Container from '../components/Container';
import Spinner from '../components/Spinner';
import { showNotification } from '../state/notifications';
import { isBrowser } from '../utils';
import getLanguage from '../utils/getLanguage';
import * as api from '../utils/api';
import * as analytics from '../utils/analytics';
import { StartPage } from '../home-delivery/StartPage';
import { BasicInfo } from '../home-delivery/BasicInfo';
import { DeliveryInstructions } from '../home-delivery/DeliveryInstructions';
import { ThankYou } from '../home-delivery/ThankYou';
import { EditDelivery } from '../home-delivery/EditDelivery';
import { formatTimeRange, setupMomentWeekdays, STEP } from '../home-delivery/utils';
import { AreYouSureNotReceive } from '../home-delivery/AreYouSureNotReceive';
import { ThankYouToServicePoint } from '../home-delivery/ThankYouToServicePoint';
import { ThankYouNotReceive } from '../home-delivery/ThankYouNotReceive';
import { AgreedDelivery } from '../home-delivery/AgreedDelivery';
import { MapDelivery } from '../home-delivery/Map';
import WoltPayment from '../home-delivery/WoltPayment';
import WoltConfirmation from '../home-delivery/WoltConfirmation';
import SelectPickupPoint from '../home-delivery/SelectPickupPoint';
import ConfirmToServicePoint from '../home-delivery/ConfirmToServicePoint';
import { Link } from '../components';

export default ({ pageContext, location: { state } }) => {
  analytics.usePageCategory('paketit');
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));
  const paths = (pageContext && pageContext.paths) || {};
  const locale = (pageContext && pageContext.locale) || 'en';
  const translate = getTranslate(useSelector(state => state.localize));
  const user = useSelector(state => state.session.user);

  const shipmentNumber = isBrowser && new URLSearchParams(location.search).get('shipment');
  const token = isBrowser && new URLSearchParams(location.search).get('token');
  const action = isBrowser && new URLSearchParams(location.search).get('action');

  const invalidParameters = isBrowser && (!shipmentNumber || !token);
  const [data, setData] = useState();
  const isLoading = !invalidParameters && (!data || Object.keys(data).length === 0);
  const [steps, setSteps] = useState([invalidParameters ? STEP.INVALID_PARAMETERS : 0]);
  const step = useMemo(() => steps[steps.length - 1], [steps]);

  const goToStep = useCallback(
    step => {
      setSteps(steps => [...steps, step]);
    },
    [setSteps]
  );
  const [selection, setSelection] = useState({});

  setupMomentWeekdays(language);

  const updateSelection = useCallback(
    data => {
      setSelection(oldValue => ({ ...oldValue, ...data }));
    },
    [setSelection]
  );

  const resetSelection = useCallback(
    params => setSelection(prev => ({ homeDeliveryAction: prev?.homeDeliveryAction, ...params })),
    []
  );

  useEffect(() => {
    switch (action) {
      case 'time':
        resetSelection({});
        goToStep(STEP.BASIC_INFO);
        break;
      case 'service-point':
        resetSelection({ notReceive: 'service-point' });
        goToStep(STEP.CHOOSE_SERVICE_POINT);
        break;
      case 'not-receive':
        resetSelection({ notReceive: 'not-receive' });
        goToStep(STEP.ARE_YOU_SURE_NOT_RECEIVE);
        break;
      default:
        // no-op
        break;
    }
  }, [action]);

  useEffect(() => {
    if (state?.step) {
      goToStep(state.step);
    }
  }, [state]);

  useEffect(() => {
    if (data || invalidParameters) {
      return;
    }
    setData({});
    (async () => {
      let response;
      try {
        response = await api.getDeliveryTimes(shipmentNumber, token, language);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setSteps([STEP.NOT_FOUND]);
          setData({ shipment: {} });
        } else if (err?.response?.data === 'INVALID_ADDRESS') {
          setSteps([STEP.INVALID_ADDRESS]);
          setData({ shipment: {} });
        } else {
          dispatch(showNotification('genericApiError'));
        }
        return;
      }
      const { shipment, availableDeliverySlots, selection, woltDelivery } = response;
      availableDeliverySlots.forEach(a => {
        a.deliverySlots.forEach(s => (s.label = formatTimeRange(s, translate)));
      });
      setData({ shipment, availableDeliverySlots, woltDelivery });
      if (selection) {
        updateSelection(selection);
        setSteps([STEP.AGREED]);
      }
    })();
  }, [
    data,
    invalidParameters,
    setData,
    updateSelection,
    setSteps,
    shipmentNumber,
    token,
    dispatch,
    translate,
    language,
  ]);

  const goNext = useCallback(
    chosenStep => {
      const nextStep = Number.isInteger(chosenStep) ? chosenStep : undefined;
      goToStep(nextStep || step + 1);
    },
    [goToStep, step]
  );

  const goBack = useCallback(() => {
    const shouldGoBackToShipmentView =
      action && [STEP.BASIC_INFO, STEP.ARE_YOU_SURE_NOT_RECEIVE, STEP.CHOOSE_SERVICE_POINT].includes(step);
    if (shouldGoBackToShipmentView) {
      window.history.back();
    } else {
      setSteps(steps => steps.slice(0, -1));
    }
  }, [setSteps, step]);

  const pageProps = { data, selection, token, goNext, goBack, goToStep, updateSelection, resetSelection };

  return (
    <Layout title={translate('delivery.agree.title')} paths={paths} locale={locale} showAppBanner="paketit">
      <Container sx={{ maxWidth: 1024, py: [2, 3, null, 4] }}>
        {isLoading ? (
          <div sx={{ minHeight: '100px', position: 'relative' }}>
            <Spinner size="medium" />
          </div>
        ) : (
          <>
            {(() => {
              switch (step) {
                case STEP.START:
                  return <StartPage {...pageProps} />;
                case STEP.BASIC_INFO:
                  return <BasicInfo {...pageProps} />;
                case STEP.DELIVERY_INSTRUCTIONS:
                  return <DeliveryInstructions {...pageProps} email={user && user.email} />;
                case STEP.READY:
                  return <ThankYou {...pageProps} />;
                case STEP.AGREED:
                  return <AgreedDelivery {...pageProps} />;
                case STEP.EDIT:
                  return <EditDelivery {...pageProps} />;
                case STEP.MAP:
                  return <MapDelivery {...pageProps} />;
                case STEP.ARE_YOU_SURE_NOT_RECEIVE:
                  return <AreYouSureNotReceive {...pageProps} />;
                case STEP.CHOOSE_SERVICE_POINT:
                  return (
                    <SelectPickupPoint
                      {...pageProps}
                      selectPickupPoint={pickupPoint => {
                        updateSelection({ pickupPoint });
                        goToStep(STEP.ARE_YOU_SURE_TO_SERVICE_POINT);
                      }}
                    />
                  );
                case STEP.ARE_YOU_SURE_TO_SERVICE_POINT:
                  return <ConfirmToServicePoint {...pageProps} {...data} />;
                case STEP.THANKS_TO_SERVICE_POINT:
                  return <ThankYouToServicePoint {...pageProps} />;
                case STEP.THANKS_NOT_RECEIVE:
                  return <ThankYouNotReceive {...pageProps} />;
                case STEP.WOLT_PAYMENT:
                  return <WoltPayment shipmentNumber={shipmentNumber} selection={selection} goBack={goBack} />;
                case STEP.WOLT_PAYMENT_OK:
                  return <WoltConfirmation state={state} />;
                case STEP.NOT_FOUND:
                  return <div>{translate('delivery.agree.notFound')}</div>;
                case STEP.INVALID_ADDRESS:
                  return (
                    <div>
                      {translate('delivery.agree.invalidAddress')}{' '}
                      <Link
                        noTitleFix
                        to="/customer-service"
                        sx={{ fontWeight: 'medium', textDecoration: 'underline' }}
                      >
                        {translate('delivery.agree.customerService')}
                      </Link>
                    </div>
                  );
                case STEP.INVALID_PARAMETERS:
                  return <div>{translate('delivery.agree.invalidParameters')}</div>;
              }
            })()}
          </>
        )}
      </Container>
    </Layout>
  );
};
