/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Form, withFormik } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import FormField from '../components/FormField';
import { ButtonBox } from './utils';
import Button from '../components/Button';
import * as yup from 'yup';

const RemarksForm = ({ isSubmitting, handleSubmit }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form
      sx={{
        width: '100%',
        my: 4,
      }}
    >
      <div sx={{ mt: 2, mb: 2 }}>{translate('delivery.agree.remarksEdit')}</div>
      <FormField name="deliveryRemarks" />

      <ButtonBox>
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('delivery.agree.save')}
        </Button>
      </ButtonBox>
    </Form>
  );
};

export const RemarksFormik = withFormik({
  mapPropsToValues: ({ deliveryRemarks = '' }) => ({
    deliveryRemarks,
  }),

  validationSchema: ({ translate }) =>
    yup.object().shape({
      deliveryRemarks: yup.string().required(translate('form.requiredField')),
    }),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'RemarksForm',
})(RemarksForm);
