/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import React, { useCallback } from 'react';
import * as api from '../utils/api';
import { showNotification } from '../state/notifications';
import { BlueInfoBox, CalenderLink } from './utils';
import { RemarksFormik } from './Remark';
import pick from 'lodash/pick';

export const EditDelivery = ({
  data: { shipment },
  selection,
  updateSelection,
  goNext,
  goBack,
  token,
  email,
}) => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const onSubmit = useCallback(
    async values => {
      updateSelection(values);
      try {
        const response = await api.updateDeliveryRemarks(
          shipment.shipmentNumber,
          {
            ...values,
            token,
          }
        );
        updateSelection({
          email: response.email || false,
          calendarEntry: response.calendarEntry,
        });
        goBack();
      } catch (err) {
        dispatch(showNotification('genericApiError'));
      }
    },
    [updateSelection, goBack, dispatch, shipment.shipmentNumber, token]
  );
  const formProps = { onSubmit, translate, ...selection };

  return (
    <>
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>
        {translate('delivery.agree.alreadyDone')}
      </Styled.h1>
      <p>
        {translate(
          'delivery.agree.shipment',
          pick(shipment, ['shipmentNumber', 'sender'])
        )}
      </p>
      <BlueInfoBox deliveryAddress={shipment.deliveryAddress} {...selection} />
      <CalenderLink
        icsBase64={selection.calendarEntry}
        shipmentNumber={shipment.shipmentNumber}
      />
      <RemarksFormik {...formProps} />
    </>
  );
};
