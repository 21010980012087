/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import * as api from '../utils/api';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncEffect } from '../hooks/useAsyncEffect';
import Spinner from '../components/Spinner';
import { BackButton } from './utils';
import getLanguage from '../utils/getLanguage';
import { reloadShipments } from '../state/shipments';
import { ThankYouNotReceiveBox } from './ThankYouNotReceiveBox';

export const ThankYouNotReceive = ({ selection, data: { shipment }, token, goBack }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const language = useSelector(state => getLanguage(state));
  const dispatch = useDispatch();
  const { loading, error } = useAsyncEffect(async () => {
    await api.setDeliveryTime(shipment.shipmentNumber, {
      ...selection,
      token,
      language,
    });
    dispatch(reloadShipments());
  }, [selection, shipment.number, dispatch, language, token]);

  if (error)
    return (
      <>
        <BackButton goBack={goBack} />
        <p sx={{ p: 2, bg: 'blueLighter', borderRadius: 1 }}>{translate('genericApiError')}</p>
      </>
    );
  if (loading)
    return (
      <div sx={{ minHeight: '100px', position: 'relative' }}>
        <Spinner size="medium" />
      </div>
    );

  return <ThankYouNotReceiveBox />;
};
