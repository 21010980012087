/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '../utils/getLanguage';
import { Button } from '../components';
import { locNavigate } from '../state/session';

export const ThankYouNotReceiveBox = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const handleGoToOwnParcels = useCallback(() => {
    dispatch(locNavigate('/my-pages/incoming-parcels'));
  }, []);

  return (
    <>
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>{translate('delivery.agree.deliveryNotReceive.thanks')}</Styled.h1>
      <p>{translate('delivery.agree.deliveryNotReceive.description')}</p>
      <Button sx={{ mt: 3 }} onClick={handleGoToOwnParcels}>
        {translate('delivery.agree.deliveryNotReceive.ownParcels')}
      </Button>
    </>
  );
};
