/** @jsx jsx */
import { jsx, Styled, Flex } from 'theme-ui';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getLanguage, { useTranslate } from '../utils/getLanguage';
import { OpenTimesRaw } from '../utils/openTimes';
import { Button } from '../components';
import { locNavigate } from '../state/session';

export const ThankYouToServicePointBox = ({ shipment, pickupPoint, selectedPickupAtTerminal }) => {
  const isTerminalPickup =
    selectedPickupAtTerminal ?? shipment?.destinationPlaceId?.toString() === pickupPoint?.officeCode?.toString();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const handleGoToOwnParcels = useCallback(() => {
    dispatch(locNavigate('/my-pages/incoming-parcels'));
  }, []);
  return (
    <>
      {isTerminalPickup ? (
        <PickupAtTerminal pickupPoint={pickupPoint} shipment={shipment} />
      ) : (
        <PickupAtServicePoint pickupPoint={pickupPoint} shipment={shipment} />
      )}
      <Button onClick={handleGoToOwnParcels}>{translate('delivery.agree.deliveryToServicePoint.ownParcels')}</Button>
    </>
  );
};

const PickupAtTerminal = ({ pickupPoint, shipment }) => {
  const language = useSelector(state => getLanguage(state));
  const translate = useTranslate();
  const openInfo = useMemo(() => {
    return OpenTimesRaw(pickupPoint?.parcelservices?.[0]?.openTimes, language);
  }, [pickupPoint]);

  return (
    <>
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>{translate('delivery.agree.thankYou')}</Styled.h1>
      <div sx={{ p: 2, bg: 'blueLighter', borderRadius: 1, mb: 3 }}>
        <p>
          {translate('delivery.agree.deliveredToServicePoint', {
            destinationPlaceName: pickupPoint.officeName,
            destinationPlaceAddress: `${pickupPoint.officeStreetAddress}, ${pickupPoint.officePostalCode} ${pickupPoint.officeCity}`,
            openInfo: openInfo ? ` (${openInfo})` : '',
          })}
        </p>
        <p>{translate('delivery.agree.shipmentNumber')}</p>
        <p sx={{ fontSize: 5, mt: -3, letterSpacing: 1 }}>{shipment.shipmentNumber}</p>
        <p sx={{ mt: -3 }}>
          {shipment?.deliveryPinCode
            ? translate('delivery.agree.deliveryPinCode')
            : translate('delivery.agree.shelfPlace')}
        </p>
        <p sx={{ fontSize: 5, mt: -3, letterSpacing: 1 }}>{shipment?.deliveryPinCode || shipment?.shelvePlace}</p>
        {!!!shipment?.deliveryPinCode ? <p sx={{ mt: -3 }}>{translate('delivery.agree.idMaybeRequired')}</p> : null}
      </div>
    </>
  );
};

const PickupAtServicePoint = ({ pickupPoint, shipment }) => {
  const translate = useTranslate();
  return (
    <>
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>{translate('delivery.agree.thankYou')}</Styled.h1>
      <p>
        {translate('delivery.agree.deliveryToServicePoint.transferedToServicePoint', {
          shipmentNumber: shipment.shipmentNumber,
        })}
      </p>
      <Flex sx={{ p: 3, flexDirection: 'column', bg: 'blueLighter', borderRadius: 1, color: 'black' }}>
        <span>{pickupPoint.officeName}</span>
        <span>
          {pickupPoint.officeStreetAddress}, {pickupPoint.officePostalCode} {pickupPoint.officeCity}
        </span>
      </Flex>
      <p>{translate('delivery.agree.deliveryToServicePoint.messageOnArrival')}</p>
    </>
  );
};
