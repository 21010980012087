/** @jsx jsx */
import { Styled, jsx, Box, Flex } from 'theme-ui';
import React, { useEffect } from 'react';
import { useState } from 'react';
import * as api from '../utils/api';
import { useLanguage, useTranslate } from '../utils/getLanguage';
import { Button, Spinner } from '../components';
import { OpenTimesRaw } from '../utils/openTimes';

export default ({ data, selectPickupPoint, token, goBack }) => {
  const translate = useTranslate();
  const { shipment } = data;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box>
        <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
          {translate('delivery.agree.deliveryToServicePoint.back')}
        </Button>
      </Box>
      <Styled.h1>{translate('delivery.agree.deliveryToServicePoint.willPickUp')}</Styled.h1>
      <ServicePointSelection shipment={shipment} token={token} selectPickupPoint={selectPickupPoint} />
    </>
  );
};

const ServicePoint = ({ servicePoint, selected, onSelect }) => {
  const language = useLanguage();
  const openTimes = OpenTimesRaw(servicePoint?.parcelservices?.[0]?.openTimes, language);
  if (!servicePoint) return null;

  return (
    <Box
      sx={{
        borderStyle: 'solid',
        borderColor: selected ? 'primary' : 'grayLight',
        backgroundColor: selected ? 'primary' : 'white',
        color: selected ? 'white' : 'black',
        borderWidth: 2,
        borderRadius: 1,
        py: 3,
        px: 3,
        cursor: 'pointer',
      }}
      role="button"
      onClick={() => onSelect(servicePoint)}
    >
      <Flex sx={{ flexDirection: 'column', gap: 0 }}>
        <div sx={{ fontWeight: 'medium' }}>{servicePoint.officeName}</div>
        <div>
          {servicePoint.officeStreetAddress}, {servicePoint.officePostalCode} {servicePoint.officeCity}
        </div>
      </Flex>
      {openTimes && <Box sx={{ mt: 3 }}>{openTimes}</Box>}
    </Box>
  );
};

const ServicePointSelection = ({ shipment, token, selectPickupPoint }) => {
  const [servicePoints, setServicePoints] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedServicePoint, setSelectedServicePoint] = useState();
  const translate = useTranslate();

  useEffect(() => {
    if (!shipment) return;
    (async () => {
      setLoading(true);
      const servicePoints = await api.getDeliveryServicePoints(shipment.shipmentNumber, token);
      setServicePoints(servicePoints);
      setLoading(false);
    })();
  }, [shipment, token]);

  if (loading) {
    return (
      <div sx={{ minHeight: '100px', position: 'relative' }}>
        <Spinner size="medium" />
      </div>
    );
  }

  return (
    <>
      <h3>{translate('delivery.agree.deliveryToServicePoint.fromTerminal')}</h3>
      <ServicePoint
        servicePoint={servicePoints?.currentServicePoint}
        selected={selectedServicePoint?.officeCode === servicePoints?.currentServicePoint?.officeCode}
        onSelect={setSelectedServicePoint}
      />
      {servicePoints?.pickupPoints?.length ? (
        <>
          <h3>{translate('delivery.agree.deliveryToServicePoint.fromServicePoint')}</h3>
          <Flex sx={{ flexDirection: 'column', gap: 3 }}>
            {(servicePoints?.pickupPoints || []).map(p => (
              <ServicePoint
                key={p.officeCode}
                servicePoint={p}
                onSelect={setSelectedServicePoint}
                selected={selectedServicePoint?.officeCode === p?.officeCode}
              />
            ))}
          </Flex>
        </>
      ) : null}

      <Button
        disabled={!selectedServicePoint}
        onClick={() => selectPickupPoint(selectedServicePoint)}
        sx={{ my: 4, px: 4 }}
      >
        {translate('delivery.agree.deliveryToServicePoint.continue')}
      </Button>
    </>
  );
};
