/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { getTranslate } from 'react-localize-redux';
import FullHeightColumn from '../components/FullHeightColumn';
import Link from '../components/Link';
import { useDispatch, useSelector } from 'react-redux';
import { reloadShipments } from '../state/shipments';
import { useEffect } from 'react';

export default ({ state }) => {
  const dispatch = useDispatch();
  const { order } = state || {};
  const translate = getTranslate(useSelector(state => state.localize));
  useEffect(() => {
    dispatch(reloadShipments());
  }, [dispatch]);

  return (
    <FullHeightColumn>
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('delivery.agree.done')}</Styled.h1>
      <p>{translate('delivery.agree.woltOnTheWay', { shipmentNumber: order.shipmentNumber })}</p>
      <a href={order.woltDelivery.woltTracking.url} target="_blank">
        {translate('delivery.agree.trackDelivery')}
      </a>
      <Link to={'/my-pages/incoming-parcels'} sx={{ textDecoration: 'underline' }}>
        {translate('delivery.agree.return')}
      </Link>
    </FullHeightColumn>
  );
};
