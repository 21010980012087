/** @jsx jsx */
import { jsx, Styled, Flex, Box } from 'theme-ui';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Button } from '../components';

export default ({ shipment, selection: { pickupPoint }, goBack, goNext }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const servicePointAddress = useMemo(
    () =>
      pickupPoint
        ? `${pickupPoint.officeStreetAddress}, ${pickupPoint.officePostalCode} ${pickupPoint.officeCity}`
        : '',
    [pickupPoint]
  );

  return (
    <>
      <Box>
        <Button onClick={goBack} variant="plain" sx={{ color: 'primary' }}>
          {translate('buyParcel.backButton')}
        </Button>
      </Box>
      <Styled.h1 sx={{ color: 'secondary' }}>{translate('delivery.agree.deliveryToServicePoint.allOK')}</Styled.h1>
      <p>
        {translate('delivery.agree.deliveryToServicePoint.confirmMessage2', {
          shipmentNumber: shipment?.shipmentNumber,
          sender: shipment?.sender,
        })}
      </p>
      {shipment?.destinationPlaceId?.toString() === pickupPoint?.officeCode?.toString() ? (
        <p>{translate('delivery.agree.deliveryToServicePoint.immediately')}</p>
      ) : (
        <p>{translate('delivery.agree.deliveryToServicePoint.oneToTwoDays')}</p>
      )}
      <Box>
        <p sx={{ fontWeight: 'semibold' }}>{translate('delivery.agree.deliveryToServicePoint.chosenServicePoint')}</p>
        <Flex sx={{ flexDirection: 'column' }}>
          <span sx={{ color: 'black' }}>{pickupPoint.officeName}</span>
          <span sx={{ color: 'black' }}>{servicePointAddress}</span>
        </Flex>
      </Box>
      <Flex
        sx={{
          bg: 'blueLighter',
          borderRadius: [0, 2],
          mt: 3,
          py: 2,
          px: 3,
        }}
      >
        <p>{translate('delivery.agree.deliveryToServicePoint.note')}</p>
      </Flex>
      <Box sx={{ mt: 4 }}>
        <Button sx={{ px: 4 }} onClick={() => goNext()}>
          {translate('delivery.agree.deliveryToServicePoint.continue')}
        </Button>
      </Box>
    </>
  );
};
