/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { Form, withFormik } from 'formik';
import FormField from '../components/FormField';
import { ButtonBox, formatAddress } from './utils';
import Button from '../components/Button';
import * as yup from 'yup';
import { Select } from '../components';
import React from 'react';

const WoltDeliveryDetailsForm = ({ isSubmitting, handleSubmit, deliveryAddress, values: { reachInstructions } }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const user = useSelector(state => state.session.user);
  return (
    <Form
      sx={{
        width: '100%',
        my: 4,
      }}
    >
      <p>{translate('delivery.agree.address')}</p>
      <Box sx={{ bg: 'blueLighter', px: 3, py: 1, borderRadius: 1, mb: 3 }}>
        <p>{formatAddress(deliveryAddress)}</p>
      </Box>
      <FormField as={Select} name="buildingType" label={translate('delivery.agree.buildingType.buildingType')}>
        <option value="apartment">{translate('delivery.agree.buildingType.apartment')}</option>
        <option value="house">{translate('delivery.agree.buildingType.house')}</option>
        <option value="office">{translate('delivery.agree.buildingType.office')}</option>
        <option value="other">{translate('delivery.agree.buildingType.other')}</option>
      </FormField>
      <FormField
        as={Select}
        name="reachInstructions"
        label={translate('delivery.agree.reachInstructions.reachInstructions')}
      >
        <option value="doorbellOrPhone">{translate('delivery.agree.reachInstructions.doorbellOrPhone')}</option>
        <option value="doorCode">{translate('delivery.agree.reachInstructions.doorCode')}</option>
        <option value="doorIsOpen">{translate('delivery.agree.reachInstructions.doorIsOpen')}</option>
        <option value="other">{translate('delivery.agree.reachInstructions.other')}</option>
      </FormField>
      {reachInstructions != 'doorIsOpen' && reachInstructions != 'other' && (
        <FormField
          name="doorCode"
          label={
            reachInstructions == 'doorCode'
              ? translate('delivery.agree.reachInstructions.doorCode')
              : translate('delivery.agree.reachInstructions.nameOrNumber')
          }
        />
      )}
      <FormField name="deliveryRemarks" label={translate('delivery.agree.remarks')} />
      {!!!user ? (
        <>
          <Box sx={{ bg: 'blueLighter', px: 3, py: 1, borderRadius: 1 }}>
            <p>{translate('delivery.agree.emailRequired')}</p>
          </Box>
          <Box sx={{ mt: 3 }}>
            <FormField name="emailAddress" label={translate('delivery.agree.emailAddress')} />
          </Box>
        </>
      ) : null}
      <Box sx={{ bg: 'blueLighter', px: 3, py: 1, borderRadius: 1 }}>
        <p>{translate('delivery.agree.addressCannotBeChanged')}</p>
      </Box>
      <ButtonBox>
        <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
          {translate('delivery.agree.ready')}
        </Button>
      </ButtonBox>
    </Form>
  );
};

const detailsSchema = translate =>
  yup.object().shape({
    doorCode: yup.string(),
    deliveryRemarks: yup.string(),
    buildingType: yup.string(),
    reachInstructions: yup.string(),
    emailAddress: yup
      .string()
      .required(translate('delivery.agree.requiredField'))
      .email(translate('delivery.agree.invalidEmail')),
  });

export const WoltDeliveryDetailsFormik = withFormik({
  mapPropsToValues: ({
    user,
    doorCode = '',
    deliveryRemarks = '',
    buildingType = 'apartment',
    reachInstructions = 'doorbellOrPhone',
    emailAddress = user?.email || '',
  }) => ({
    doorCode,
    deliveryRemarks,
    buildingType,
    reachInstructions,
    emailAddress,
  }),

  validationSchema: ({ translate }) => detailsSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'WoltDeliveryDetailsForm',
})(WoltDeliveryDetailsForm);
