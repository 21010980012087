/** @jsx jsx */
import { jsx, Styled, Label } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { BackButton, ButtonBox } from './utils';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Button from '../components/Button';
import Switch from '../components/Switch';

export const AreYouSureNotReceive = ({ goNext, goBack }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const [sure, setSure] = useState(false);
  const handleSetSure = useCallback(
    event => {
      setSure(event.target.checked);
    },
    [setSure]
  );
  return (
    <>
      <BackButton goBack={goBack} />
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>
        {translate('delivery.agree.areYouSure')}
      </Styled.h1>
      <p>{translate('delivery.agree.areYouSureDescription')}</p>
      <Label>
        <span sx={{ flex: 'auto' }}>{translate('delivery.agree.iAmSure')}</span>
        <Switch checked={sure} onChange={handleSetSure} />
      </Label>
      <ButtonBox>
        <Button sx={{ mt: 4 }} onClick={goNext} disabled={!sure}>
          {translate('delivery.agree.continue')}
        </Button>
      </ButtonBox>
    </>
  );
};
