/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useTranslate } from '../utils/getLanguage';
import { useMemo } from 'react';

const PersonalHandoverNote = ({ shipment, sxx }) => {
  const translate = useTranslate();

  const noteText = useMemo(() => {
    if (shipment?.additionalServices?.some(s => s.additionalServiceCode === 'IR18')) {
      return 'delivery.agree.ageRestrictedHandover18';
    } else if (shipment?.additionalServices?.some(s => s.additionalServiceCode === 'IR20')) {
      return 'delivery.agree.ageRestrictedHandover20';
    } else if (shipment?.additionalServices?.some(s => s.additionalServiceCode === 'HL')) {
      return 'delivery.agree.personalHandover';
    } else {
      return null;
    }
  }, [shipment]);

  if (!noteText) {
    return null;
  }

  return (
    <Box sx={{ backgroundColor: 'lightYellow', px: 2, py: 1, borderRadius: 1, ...sxx }}>
      <p sx={{ ml: 2, mr: 2 }}>{translate(noteText)}</p>
    </Box>
  );
};

export default PersonalHandoverNote;
